import React, { useState, useEffect, useContext } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import RestaurantForm from './Restaurant/RestaurantForm';
import { ApiHelpers } from '../../helpers';
import { Form, Tabs, Skeleton } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { useCompany } from '../../swr';
import { useRecoilState } from 'recoil';
import { restaurantDetailsState, activeRestaurantState } from '../../recoil';
import Onboarding from './Onboarding';

const { TabPane } = Tabs;

function LocationsDetail() {
    // Get AuthUser ID from Firebase
    const firebaseAuthUser = useContext(AuthUserContext);
    let { restaurantId } = useParams();
    const [formRestaurant] = Form.useForm();

    const [scheduleList, setScheduleList] = useState([]);
    const INITIAL_STATE_RESTAURANT = {
        title: '',
        appDescription: '',
        placeAddress: '',
        cityId: null,
        uniqueIdentificationToken: null,
        logo: null,
        coverImage: null,
        logoPath: null,
        coverImagePath: null,
        active: false,
        latitude: '',
        longitude: '',
        cuisineTypes: null,
        companyIdentificationToken: '',
        sysRestaurantType: '',
        subscriptionPlan: 'Free',
        implementationMode: true,
        defaultCategoryType:'food'
    };
    const [restaurantDetails, setRestaurantDetails] = useState({ ...INITIAL_STATE_RESTAURANT });
    const INITIAL_STATE_INTEGRATION = {
        uniqueIdentificationToken: '',
        restaurantToken: restaurantId,
        freyaApiKey: null,
        endpoint: '',
        ebrizaClientId: '',
        ebrizaPublicKey: '',
        ebrizaSecret: '',
    };
    const [integrationSettings, setIntegrationSettings] = useState({ ...INITIAL_STATE_INTEGRATION });
    const [restaurantDetailsStateLocal, setRestaurantDetailsStateLocal] = useRecoilState(restaurantDetailsState);
    const [entryFound, setEntryFound] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [refreshData, setRefreshData] = useState(false);

    const [activeRestaurant, setActiveRestaurant] = useRecoilState(activeRestaurantState);

    const navigate = useNavigate();
    const { data: companyDetails, isLoading: isLoadingCompanyDetails } = useCompany('getDetails', firebaseAuthUser.uid);
    let companyIdentificationToken = null;

    if (companyDetails) {
        companyIdentificationToken = companyDetails.uniqueIdentificationToken;
    }

    const routeLocation = useLocation();

    const callbackTabs = (key) => {
        setActiveTabKey(key);
        navigate(key);
    };

    const locationFormLayout = {
        layout: 'horizontal',
        form: formRestaurant,
        labelCol: { span: 4 },
        wrapperCol: { span: 9 },
        size: 'large',
        labelAlign: 'left',
    };
    const tailLayout = {
        wrapperCol: { offset: 4, span: 9 },
    };

    useEffect(() => {
        if (restaurantId !== 'new') {
            setIsLoading(true);
            // Get Restaurant by Id
            ApiHelpers.getRestaurantsByCode(restaurantId).then(
                (response) => {
                    if (response.data.message) {
                        setEntryFound(false);
                    } else {
                        setRestaurantDetails({
                            ...response.data,
                            companyIdentificationToken: companyIdentificationToken,
                            menu: [],
                        });
                        setRestaurantDetailsStateLocal({ ...response.data, companyIdentificationToken: companyIdentificationToken, menu: [] });
                        setScheduleList(response.data.schedule);
                        setIntegrationSettings({ ...response.data.integrationSettings });
                        setEntryFound(true);
                    }
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [restaurantId, refreshData]);

    useEffect(() => {
        if (routeLocation.hash) {
            setActiveTabKey(routeLocation.hash);
        } else setActiveTabKey('#details');
    });

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <AdminLayout authUser={authUser} pageTitle={restaurantDetails.title}>
                    <h2>{restaurantDetails && restaurantDetails.title ? restaurantDetails.title : 'Adaugă restaurant'}</h2>
                    {isLoadingCompanyDetails ? (
                        <Skeleton active></Skeleton>
                    ) : companyDetails.message ? (
                        <Onboarding />
                    ) : isLoading ? (
                        <Skeleton active></Skeleton>
                    ) : entryFound === true || restaurantId === 'new' ? (
                        <RestaurantForm
                            formLayout={locationFormLayout}
                            tailLayout={tailLayout}
                            restaurantId={restaurantId}
                            restaurantDetails={restaurantDetails}
                            setRestaurantDetails={setRestaurantDetails}
                            isDrawer={false}
                            scheduleList={scheduleList}
                            setScheduleList={setScheduleList}
                            integrationSettings={integrationSettings}
                            setIntegrationSettings={setIntegrationSettings}
                            actionType={restaurantId === 'new' ? 'new' : 'edit'}
                            setRefreshData={setRefreshData}
                        />
                    ) : entryFound === false ? (
                        <Onboarding step={2} />
                    ) : (
                        <Skeleton active />
                    )}
                </AdminLayout>
            )}
        </AuthUserContext.Consumer>
    );
}
const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(LocationsDetail);
